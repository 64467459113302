import 'core-js/modules/es6.set'
import 'core-js/modules/es6.map'
import 'whatwg-fetch'
import 'raf/polyfill'
import 'intersection-observer'

// eslint-disable-next-line import/prefer-default-export

export const onClientEntry = () => {
  // Without this function body the import will not be picked up.
}
